import Dynamic1 from '@/assets/images/home/dynamic1.jpg'
import Dynamic2 from '@/assets/images/home/dynamic2.jpg'
import Dynamic3 from '@/assets/images/home/dynamic3.jpg'
import Dynamic4 from '@/assets/images/home/dynamic4.jpg'
import Dynamic5 from '@/assets/images/home/dynamic5.jpg'
import Dynamic6 from '@/assets/images/home/dynamic6.jpg'
import Dynamic7 from '@/assets/images/home/dynamic7.jpg'
import Dynamic8 from '@/assets/images/home/dynamic8.jpg'
import Dynamic9 from '@/assets/images/home/dynamic9.jpg'
import Dynamic11 from "@/assets/images/home/dynamic11.jpg"
import Dynamic10 from "@/assets/images/home/dynamic10.jpg"
import Dynamic12 from "@/assets/images/home/dynamic12.jpg"
import Dynamic13 from "@/assets/images/home/dynamic13.jpg"

const data = [
  {
    index: 12,
    date: '2024-2025',
    title: '喜报！熙诚教育投资获“2024-2025最受企业欢迎培训机构”大奖',
    desc: '日前，由《培训》杂志主办的第十二届中国企业教育培训服务会展圆满落幕，熙诚教育投资凭借深厚的行业积累与创新实力 ...',
    img: Dynamic13
  },
  {
    index: 11,
    date: '2024.11.15',
    title: '熙诚教育投资亮相第13届培博会，斩获2024培博会·培英奖“新锐品牌TOP10”',
    desc: '11月15日，2024培博会·培英奖重磅出炉，熙诚教育投资凭借专业实力与年度卓越表现，在众多企培机构中脱颖而出，荣获2024培博会·培英奖“新锐品牌TOP10” ...',
    img: Dynamic12
  },
  {
    index: 9,
    date: '2024.09.26',
    title: '荣誉时刻|熙诚教育投资荣获第七届年度经济大会“2024中国（行业）创新品牌100强”奖项',
    desc: '凝心聚力同奋进，群贤毕至谋发展，9月26日，2024（第七届）年度经济大会在北京隆重举行。大会以“新质生产力引领经济高质量发展”为主题，吸引了来自政府 ...',
    img: Dynamic11
  },
  {
    index: 10,
    date: '2024.09.10',
    title: '荣誉时刻|熙诚教育投资荣获第三届T20培训咨询行业企业家论坛“最佳合作潜力奖”',
    desc: '9月9日至９月10日，由T20培训机构董事长俱乐部主办的“创新破局·逆势增长：弘扬企业家精神 聚焦高质量发展” ——2024第三届T20培训咨询行业企业家论坛 ...',
    img: Dynamic10
  },
  {
    index: 0,
    date: '2024.07.17',
    title: '辞旧迎新，未来可7丨熙诚教育投资七周年',
    desc: '过去7年里，我们种下真诚和专业。此刻，我们收获了朋友和伙伴。熙诚教育投资七周年，我们跟合作伙伴一起庆祝。过去7年，我们跟中国企业一起成长，彼此成就 ...',
    img: Dynamic1,
    url: 'https://mp.weixin.qq.com/s/H30BKx_AKxy2ENlWuu7zLw'
  },
  {
    index: 1,
    date: '2024.01.08',
    title: '熙诚教育投资积极投身教育公益事业，协办春禾第七期校长研修坊',
    desc: '1月3日至5日，由上海春禾青少年发展中心主办、熙诚教育投资协办的春禾第七期校长研修坊在北京开展，来自全国各地的春禾项目学校校长30余人参加了研修活动 ...',
    img: Dynamic2,
    url: 'https://mp.weixin.qq.com/s/5VCdpYUcE4XQgp_H0XJwkQ'
  },
  {
    index: 2,
    date: '2023.12.08',
    title: '熙诚教育投资携手北大国发院开展承泽企业家圆桌会，探寻中国品牌成长之路',
    desc: '2023年，应时代的新变局和企业家的新需求，北大国发院正式推出了【承泽企业家研修班】，重点培养企业家在新变局时代急需的新思维、新能力 ...',
    img: Dynamic3,
    url: 'https://mp.weixin.qq.com/s/pFBu-FgFo4jOCTa42YL4Tg'
  },
  {
    index: 3,
    date: '2023.12.05',
    title: '荣誉时刻丨熙诚教育投资荣获第15届博奥奖“年度最佳数字化人才赋能服务商”',
    desc: '上个月，由在线教育资讯网（Online-edu）主办的第15届中国企业数智化学习大会（CEFE）在北京隆重举行，大会结合当下热门的人工智能、数字人 ...',
    img: Dynamic4,
    url: 'https://mp.weixin.qq.com/s/v-tjJooSZkkDEaEozbGS5Q'
  },
  {
    index: 4,
    date: '2023.10.25',
    title: '成都高新区国资金融局一行莅临熙诚教育投资座谈交流',
    desc: '10月24日，成都高新区国资金融局党组书记、局长彭涌一行莅临熙诚教育投资指导工作，双方就“资本赋能，共促企业高质量发展”主题进行工作座谈 ...',
    img: Dynamic5,
    url: 'https://mp.weixin.qq.com/s/kIbcZrBU4zMSly7tRJvFjA'
  },
  {
    index: 5,
    date: '2023.09.15',
    title: '产品为王丨光合企学亮相2023企业教育培训博览会！',
    desc: '9月14日至9月15日，新华报业传媒集团《培训》杂志发起的“2023企业教育培训博览会”在深圳大中华喜来登酒店隆重举行。光合企学作为企培行业的优秀代表 ...',
    img: Dynamic6,
    url: 'https://mp.weixin.qq.com/s/qYhSY1VC7J_n7M4yPspeQg'
  },
  {
    index: 6,
    date: '2023.07.31',
    title: '为进一步深化战略合作，熙诚教育投资一行赴雄安新区调研',
    desc: '在广袤的冀中平原，一幅新时代美好的雄安画卷正快速铺展。今天的雄安，正处在如火如荼的建设进行时，并且轮廓已经越来越清晰。7月28日，熙诚教育投资总经理李越 ...',
    img: Dynamic7,
    url: 'https://mp.weixin.qq.com/s/twdQLLcufsjb_QQP2C2uPQ'
  },
  {
    index: 7,
    date: '2023.07.21',
    title: '有为·可为——熙诚教育投资六周年庆圆满落幕',
    desc: '作为企业培训行业的国企先锋，六年来，熙诚教育投资始终坚持以实干笃定前行，聚焦中国企业成长，深耕产品与服务，持续突破创新，谋求企业与客户 ...',
    img: Dynamic8,
    url: 'https://mp.weixin.qq.com/s/n8yUpGZrM3DZBJ5nqIvqDw'
  },
  {
    index: 8,
    date: '2023.06.05',
    title: '深化合作丨雄安管委会领导莅临熙诚教育投资指导交流',
    desc: '2023年6月2日，雄安管委会领导莅临熙诚教育投资指导工作，双方就企业发展话题进行深度交流。今天的雄安新区，经过6年规划建设，已进入大规模建设 ...',
    img: Dynamic9,
    url: 'https://mp.weixin.qq.com/s/F_RJcE_qkdmfJkYnlL07Dw'
  }
]

export default data